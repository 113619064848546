





































































































































import BaseSvgIcon from '@/components/base/BaseSvgIcon.vue';
import uuid4 from 'uuid/v4';
import Vue, { VueConstructor } from 'vue';
import VueMultiSelect from 'vue-multiselect';
import {
  cloneDeep as _cloneDeep,
  find as _find,
  isEqual as _isEqual,
  isNil as _isNil,
  isNumber as _isNumber,
  isString as _isString,
  isUndefined as _isUndefined,
} from 'lodash';
import { DIMENSION } from '@/utils/dimensions';
import { mixinDropdownPositioning } from '@/components/mixins/dropdownPositioning';
import { POSITION } from '@/utils/positions';
import { utIsEmpty } from '@/utils/empty';
import 'vue-multiselect/dist/vue-multiselect.min.css';

export default (Vue as VueConstructor).extend({
  name: 'BaseSelect',

  components: {
    BaseSvgIcon,
    VueMultiSelect,
  },

  mixins: [
    mixinDropdownPositioning,
  ],

  props: {
    allowEmpty: {
      type: Boolean,
      default: true,
    },

    anchorData: {
      type: Array,
      default: function() {
        return [
          DIMENSION.WIDTH,
          POSITION.LEFT,
        ];
      },
    },

    closeOnSelect: {
      type: Boolean,
      default: true,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    hasEmptyOption: {
      type: Boolean,
      default: true,
    },

    isAction: {
      type: Boolean,
      default: false,
    },

    filterable: {
      type: Boolean,
      default: false,
    },

    groupLabelProperty: {
      type: String,
      default: null,
    },

    groupOptionsProperty: {
      type: String,
      default: null,
    },

    groupSelectable: {
      type: Boolean,
      default: false,
    },

    label: {
      type: [
        String,
        null,
      ],
      default: null,
    },

    limit: {
      type: Number,
      default: 99999,
    },

    multiSelect: {
      type: Boolean,
      default: false,
    },

    newOptionMessage: {
      type: String,
      default: function() { // Arrow function not working
        return this.$t('common.select.dropdown.newOption');
      },
    },

    noOptions: {
      type: String,
      default: function() { // Arrow function not working
        return this.$t('common.select.dropdown.noOptions');
      },
    },

    noResult: {
      type: String,
      default: function() { // Arrow function not working
        return this.$t('common.select.dropdown.noResult');
      },
    },

    options: {
      type: Array,
      default: function() {
        return [];
      },
    },

    placeholder: {
      type: String,
      default: '',
    },

    savedProperty: {
      type: String,
      default: 'ID',
    },

    searchable: {
      type: Boolean,
      default: true,
    },

    shownProperty: {
      type: String,
      default: 'name',
    },

    shownPropertyIcon: {
      type: String,
      default: 'icon',
    },

    shownPropertyOptionRight: {
      type: String,
      default: '',
    },

    shownPropertySecondLine: {
      type: String,
      default: 'subtitle',
    },

    taggable: {
      type: Boolean,
      default: false,
    },

    value: {
      type: [
        Array,
        Number,
        Object,
        String,
      ],
      default: '',
    },

    variant: {
      type: String,
      default: 'standard',
    },
  },

  data() {
    return {
      droppingDown: true,
      isOpen: false,
      optionList: [],
      spaceAbove: 0,
      spaceBelow: 0,
      triggerIsVisible: null,
      utIsEmpty,
    };
  },

  computed: {
    animate(): boolean {
      if (this.isOpen) {
        return true;
      }

      if (this.internalValue) {
        const currentValue = this.internalValue[this.shownProperty];

        if (_isNumber(currentValue) && !_isNil(this.label)) {
          return true;
        }

        if (_isString(currentValue) && !_isNil(this.label)) {
          if (currentValue.length > 0) {
            return true;
          }
        }
      }

      return false;
    },

    classes(): Object {
      return {
        [`select--${this.variant}`]: true,
        'select--animated': this.animate,
        'select--disabled': this.disabled,
        'select--filterable': this.filterable,
        'select--as-action': this.isAction,
        'select--not-searchable': !this.searchable,
        'select--open': this.isOpen,
      };
    },

    getPlaceholder(): string {
      if ((
        utIsEmpty(this.internalValue) && !utIsEmpty(this.placeholder) && _isNil(this.label)) ||
        (!_isNil(this.label) && this.animate && !utIsEmpty(this.placeholder))
      ) {
        return this.placeholder;
      }

      return null;
    },

    internalValue: {
      get() {
        if (this.groupSelectable) {
          return this.value.map(value => {
            const option = _find(
              this.optionList,
              option => option[this.savedProperty] === value[this.savedProperty],
            );

            if (
              !_isUndefined(option) &&
              value[this.savedProperty] !== this.$t('common.property.empty')
            ) {
              return option;
            }

            return value;
          });
        }

        return this.value;
      },

      set(value) {
        if (!_isEqual(this.value, value)) {
          this.$emit('input', value);

          this.$emit('focus', false);
        }
      },
    },

    multiple(): void {
      return this.filterable || this.multiSelect;
    },

    vueMultiSelectModel: {
      get() {
        // vue-multi-select hides the input field if it's an "empty" object
        // so we need to set it to an empty array
        return utIsEmpty(this.internalValue) ? [] : this.internalValue;
      },

      set(value) {
        this.internalValue = value;
      },
    },
  },

  watch: {
    options() {
      this.optionList = this.options;

      this.setOptionList();
    },
  },

  methods: {
    addOption(option): void {
      const newOption = {};
      const newValue = _cloneDeep(this.value);

      newOption[this.shownProperty] = option;

      if (this.shownProperty !== this.savedProperty) {
        newOption[this.savedProperty] = uuid4();
      }

      newValue.push(newOption);

      this.internalValue = newValue;

      this.setOptionList();
    },

    async checkValue(clickedValue, isRemoved = false): Promise<any> {
      await this.$nextTick();

      const tags = Array.from(this.$el.getElementsByClassName('multiselect__tag'));

      tags.forEach((tag: HTMLElement) => {
        if (
          !tag.hasAttribute('empty') &&
          tag.getElementsByTagName('span')[0].innerHTML === this.$t('common.property.empty')
        ) {
          tag.setAttribute('empty', '');
        }

        if (
          tag.hasAttribute('empty') &&
          tag.getElementsByTagName('span')[0].innerHTML !== this.$t('common.property.empty')
        ) {
          tag.removeAttribute('empty');
        }
      });

      if (this.groupSelectable && !isRemoved && !_isUndefined(clickedValue?.children)) {
        const newValueList = _cloneDeep(this.value);

        this.value.forEach((value, index) => {
          const option = _find(
            this.optionList,
            option => option[this.savedProperty] === value[this.savedProperty],
          );

          if (!_isUndefined(option?.children)) {
            newValueList[index].children = option.children;
          }

          clickedValue.children.forEach(child => {
            if (child[this.savedProperty] === value[this.savedProperty]) {
              newValueList.splice(index, 1);
            }
          });
        });

        this.$emit('input', newValueList);

        this.$emit('focus', false);
      }
    },

    hideList(): void {
      if (!_isUndefined(this.$refs['multi-select-component'])) {
        (this.$refs['multi-select-component'] as Vue & { deactivate: () => void }).deactivate();
      }
    },

    limitText(count: number): string {
      return `... (${count})`;
    },

    onClose(): void {
      this.isOpen = false;

      this.$emit('focus', false);
    },

    async onOpen(): Promise<any> {
      this.isOpen = true;

      await this.$nextTick();

      this.positionList();

      this.$emit('focus', true);
    },

    optionClasses(option): Object {
      return {
        'multiselect__option--disabled': option.$isDisabled,
        'multiselect__option--selected': option.$isDisabled && this.groupSelectable,
      };
    },

    async setOptionList(): Promise<any> {
      await this.$nextTick();
      if (this.filterable && this.taggable) {
        this.value.forEach(val => {
          const valueExists = _find(this.optionList,
            option => {
              return option[this.shownProperty] === val[this.shownProperty] &&
                option[this.savedProperty] === val[this.savedProperty];
            });

          if (!valueExists) {
            this.optionList.push(val);
          }
        });
      }

      if (this.filterable && this.hasEmptyOption) {
        const emptyExists = _find(
          this.optionList,
          option => option[this.shownProperty] === this.$t('common.property.empty'),
        );

        if (!emptyExists) {
          const emptyOption = {};

          emptyOption[this.shownProperty] = this.$t('common.property.empty');

          if (this.shownProperty !== this.savedProperty) {
            emptyOption[this.savedProperty] = this.$t('common.property.empty');
          }

          this.optionList.push(emptyOption);
        }
      }

      if (this.groupSelectable) {
        this.value.forEach(value => {
          const option = _find(
            this.optionList,
            option => option[this.savedProperty] === value[this.savedProperty],
          );

          if (option?.children) {
            option.children.forEach(child => {
              this.optionList.forEach(option => {
                if (option[this.savedProperty] === child[this.savedProperty]) {
                  option.$isDisabled = true;
                }
              });
            });
          }
        });
      }
    },
  },

  mounted(): void {
    this.optionList = this.options;

    this.setOptionList();

    this.checkValue();

    const container = this.$el;
    const list = this.$el.querySelector('.multiselect__content-wrapper');

    this.decoupleList(container, list);
  },
});
